import React, { useState } from 'react';
import { Card, Button, Image } from 'react-bootstrap'
import {
    Link
} from "react-router-dom";
import { faAward, faVideo, faUsers, faChalkboardTeacher, faLightbulb } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AcademyNavbar from './AcademyNavbar'


function Program() {
    const [price, setPrice] = useState(95000);
    const [programName, setProgramName] = useState('Leading Digital Transformation and Innovation');
    const [shortDescription, setshortDescription] = useState('Gain the knowledge and confidence to successfully leverage the power of digital technologies for competitive advantage');
    const [tags, setTags] = useState([{ text: 'Leadership' }, { text: 'Technology' }]);
    const [about, setAbout] = useState('We are in the midst of a digital tsunami. Digital technologies have progressed exponentially over the last decades and a tipping point has been reached. We are seeing new intelligent programs beat the best human experts in complex domains such as medicine. We are witnessing the emergence of new digital leaders that are dominating multiple business sectors on the strength of their agile digital platforms/ services and are threatening to beat traditional leaders by significant margins.\nFurther the pace of progress in digital technologies shows no signs of slowing down. All industries are susceptible to the forces of digital transformation and most executives are or will soon be confronted by the dynamics of digital disruption. There will be significant new opportunities as the underlying forces of digital disruption and value creation are understood and leveraged. Succeeding in this rapidly changing world will require both a solid understanding of digital products, services and business models and an inspirational leadership style to be bold, take risks and lead complex change.');
    const [learnings, setLearning] = useState([{ text: 'The goal of this program is to provide you with the knowledge and tools to lead the process of digital transformation and innovation in your organization. Lectures and case studies will provide a rich forum for in-class discussions and provide an effective combination of theory and practice. You will assemble key insights along three important dimensions' },
    { learning: 'Technology' }]);
    const [modules, setModules] = useState([
        { module: 'Orientation Module', sessions: 1, moduleName: '', description: 'You will be welcomed to the program and start connecting with fellow participants in the program and get an overview of the forthcoming program. You will also start forming teams and start considering questions to explore in your customized project work.' },
        { module: 'Module 1', sessions: 2, moduleName: 'Intelligent data platform', description: 'An intelligent integrated data platform lies at the core of a smart digital roadmap,\n Big data is a core strength, \n Smart analytics are employed, \n Power of Internet of Things (IOT) is leveraged' },
        { module: '', sessions: 1, moduleName: 'Live Virtual Session with Industry Expert', description: '' },
        { module: 'Module 2', sessions: 2, moduleName: 'Digital products and services', description: 'Customer value delivery is enhanced through the creation of appropriate digital products and services, Information content of products and services is maximized, Relationships are enhanced through digital connectivity with customers, Mass customization is the norm' },
        { module: '', moduleName: 'Live Virtual Group Work led by Program Coach', description: '' },
        { module: 'Module 3', sessions: 2, moduleName: 'Intelligent data platform', description: 'An intelligent integrated data platform lies at the core of a smart digital roadmap, Big data is a core strength, Smart analytics are employed, Power of Internet of Things (IOT) is leveraged' },
        { module: '', sessions: 1, moduleName: 'Live Virtual Session with Industry Expert', description: '' },
        { module: 'Module 4', sessions: 2, moduleName: 'Intelligent data platform', description: 'An intelligent integrated data platform lies at the core of a smart digital roadmap, Big data is a core strength, Smart analytics are employed, Power of Internet of Things (IOT) is leveraged' },
        { module: '', sessions: 1, moduleName: 'Live Virtual Group Work led by Program Coach', description: '' },
        { module: 'Module 4', sessions: 2, moduleName: 'Intelligent data platform', description: 'An intelligent integrated data platform lies at the core of a smart digital roadmap, Big data is a core strength, Smart analytics are employed, Power of Internet of Things (IOT) is leveraged' },
        { module: 'Post Program', sessions: 1, moduleName: '', description: 'Record of attendance from the University of California, Los Angeles (UCLA Extension), Knowledge updates, Alumni Community, Social interactions' }
    ]);
    const [count, setCount] = useState(0)

    function LiveClasses(sessions) {
        for (var i = 0; i < sessions; i++) {
            return (
                <>
                    <span className="livemarker">LIVE{count}</span>
                </>
            )
        }
    }

    return (
        <div>
            <AcademyNavbar />
            <div className="container-fluid pricing-bar py-3">
                <div className="container">
                    <div className="row justify-content-between">
                        <div className="col-6">
                            <p className="font-weight-bold mb-0">
                                <span className="d-none d-md-block pt-2">{programName}</span>
                                <span className="d-block d-md-none">From ₹{price}/ person </span>
                            </p>
                        </div>
                        <div className="col-6 text-right">
                            <p className="font-weight-bold mb-0">
                                <span className="mr-2 d-none d-md-inline">From ₹{price}/ person </span>
                                <Button variant="primary d-inline font-weight-bold mb-0" style={{ marginTop: '-5px' }}>See dates</Button>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid bg-black pt-5">
                <div className="container">
                    <div className="row mb-5 flextry" style={{ width: "100%" }}>
                        <div class="flex-container">
                            <div id="MainBanner" className="d-none d-md-block">
                                <video width="100%" controlsList="nodownload" controls loop muted style={{ maxWidth: "300px" }}>
                                    <source src="https://firebasestorage.googleapis.com/v0/b/igesia-web.appspot.com/o/images%2Fpublic%2Fsample-video.mp4?alt=media&token=1eccfe7a-8468-4f89-874e-f6dfbcf69e38" type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                            <div style={{
                                backgroundImage: "url(https://firebasestorage.googleapis.com/v0/b/igesia-web.appspot.com/o/images%2Fpublic%2Fsample-img.jpg?alt=media&token=26933727-eb73-463e-a1f8-d0c14217ddbe)"
                            }}
                            ></div>
                            <div>
                                <div style={{ backgroundImage: "url(https://www.hosanagar.com/wp-content/uploads/2019/01/kartik_image02.png)" }}></div>
                                <div style={{ backgroundImage: "url(https://apps.business.cornell.edu/faculty-research/faculty/photo/sd599)" }}></div>
                                <div style={{ backgroundImage: "url(https://lh3.googleusercontent.com/proxy/WDxmeHoBZl-RzRiK9VvU1FZ1KGcvu_iqjkdDrPpUADK7sI1NcR6-yc3SNGDts1qRaKsZKJ67Gvmgid-2aAi7UIoiDimKvMmyHV2GhKBqrvZtahYRCHiF4dsAb1klpw)" }}></div>
                            </div>
                            <div style={{
                                backgroundImage: "url(https://firebasestorage.googleapis.com/v0/b/igesia-web.appspot.com/o/images%2Fpublic%2Fsample-img.jpg?alt=media&token=26933727-eb73-463e-a1f8-d0c14217ddbe)"
                            }}
                            ></div>
                            <div style={{
                                backgroundImage: "url(https://firebasestorage.googleapis.com/v0/b/igesia-web.appspot.com/o/images%2Fpublic%2Fsample-img.jpg?alt=media&token=26933727-eb73-463e-a1f8-d0c14217ddbe)"
                            }}
                            ></div>

                        </div>
                    </div>

                    <div className="row program-header pb-5">
                        <div className="col-md-6 text-white mb-3">
                            <h1 className="font-weight-light h2">{programName}</h1>
                            <p className="font-weight-light lead">{shortDescription}</p>
                            {tags.map(item => (
                                <span key={item.id} class="badge text-uppercase badge-dark mr-2 mb-2 font-weight-normal p-2">{item.text}</span>
                            ))}
                        </div>
                        <div className="col-md-6 text-white ">
                            <div className="border-dark border-top border-bottom py-2">
                                <div className="row">
                                    <div className="col-md-1 col-1 pt-2 "><FontAwesomeIcon icon={faAward} size="2x" style={{ color: "orange" }} /></div>
                                    <div className="col-md-8 col-11 certification-text">Attested with a record of attendance from the<br /> <strong>University of California, Los Angeles (UCLA Extension)</strong></div>
                                    <div className="col-md-3 pt-2 ">
                                        <img className="logo" src="https://firebasestorage.googleapis.com/v0/b/igesia-web.appspot.com/o/images%2Fpublic%2Fucla_reverse.png?alt=media&token=b58a0dd8-71db-4489-b8bf-29293a09c252"
                                            width="100%" />
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-6">
                                    <FontAwesomeIcon icon={faLightbulb} color="grey" />
                                    <p className="small">A program designed to help you to lead through technology disruptions</p>
                                </div>
                                <div className="col-6">
                                    <FontAwesomeIcon icon={faVideo} color="grey" />
                                    <p className="small">Delivered via live virtual teaching on the Igesia learning platform</p>
                                </div>
                                <div className="col-6">
                                    <FontAwesomeIcon icon={faUsers} color="grey" />
                                    <p className="small">Integrated with group work on real world projects</p>
                                </div>
                                <div className="col-6">
                                    <FontAwesomeIcon icon={faChalkboardTeacher} color="grey" A />
                                    <p className="small">Connecting you to the best faculty worldwide</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className="container-fluid bg-light py-5 program-content-web">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4">
                                <h2>About the Program</h2>
                            </div>
                            <div className="col-md-8 ">
                                {about.split("\n").map(item => (
                                    <p>{item}</p>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid py-5  program-content-web" style={{ background: "#EFEFEF" }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4">
                                <h2>What you will learn</h2>
                            </div>
                            <div className="col-md-8 ">
                                <p>The goal of this program is to provide you with the knowledge and tools to lead the process of digital transformation and innovation in your organization. Lectures and case studies will provide a rich forum for in-class discussions and provide an effective combination of theory and practice. You will assemble key insights along three important dimensions</p>
                                <p>
                                    <ol>
                                        <li>
                                            <p><h3>Disruptive digital technologies</h3>The program will provide an overview of the key disruptive technologies along with an indication of forthcoming trends</p>
                                        </li>
                                        <li>
                                            <p><h3>Digital transformation and innovation</h3>Examples of innovative applications of digital technologies in various sectors will be covered along with their strategic rationales and implementation challenges</p>
                                        </li>
                                        <li>
                                            <p><h3>Digital culture and mindsets</h3>A successful digital transformation requires strong and resolute leadership from the top complemented by a digital mindset that permeates the organization’s culture and mission</p>
                                        </li>
                                    </ol>
                                </p>
                                <p>Upon completion of the program, you will be ready to apply your knowledge to lead strategic decision making to leverage AI technologies in your business.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid py-5 text-white program-content-web">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4">
                                <h2>Your Learning Journey</h2>
                            </div>
                            <div className="col-md-8">
                                <p><span className="text-white">The program is structured equally along the five essential elements of building a smart digital roadmap for an organization</span></p>
                                <div className="program-structure">
                                    {modules.map(item => (
                                        <p>
                                            {LiveClasses(item.sessions)}

                                            {item.module !== ""
                                                ? item.module == "Orientation Module"
                                                    ? <span className="d-block font-weight-bold text-uppercase text-warning small">{item.module}</span>
                                                    : item.module == "POST PROGRAM"
                                                        ? <span className="d-block font-weight-bold text-uppercase text-warning small">{item.module}</span>
                                                        : <span className="d-block font-weight-bold text-uppercase text-primary small">{item.module}</span>
                                                : null
                                            }
                                            {item.moduleName !== "" ? <h4>{item.moduleName}</h4> : null}
                                            {item.description !== ""
                                                ? item.description.split("\n").map(item => (
                                                    <span className="font-weight-light">{item}<br /></span>
                                                ))
                                                : null}
                                        </p>
                                    ))}
                                </div>
                                <hr />
                                {/* { module: 'Module 1', sessions: 2, moduleName: 'Intelligent data platform', description: 'An intelligent ' } */}
                                <div className="program-structure">
                                    <p>
                                        <span className="livemarker">LIVEx</span>
                                        <small className="d-block font-weight-bold text-warning">ORIENTATION MODULE</small>
                                        <span className="font-weight-light">You will be welcomed to the program and start connecting with fellow participants in the program and get an overview of the forthcoming program. You will also start forming teams and start considering questions to explore in your customized project work.
                                        </span>
                                    </p>
                                    <p>
                                        <span className="livemarker">LIVE</span>
                                        <span className="livemarker">LIVE</span>
                                        <small className="d-block font-weight-bold text-primary">MODULE 1</small>
                                        <h4>Intelligent data platform</h4>
                                        <span className="font-weight-light">An intelligent integrated data platform lies at the core of a smart digital roadmap
                                            <ul>
                                                <li>Big data is a core strength</li>
                                                <li>Smart analytics are employed</li>
                                                <li>Power of Internet of Things (IOT) is leveraged</li>
                                            </ul>
                                        </span>
                                    </p>
                                    <p>
                                        <span className="livemarker">LIVE</span>
                                        <h4>Live Virtual Session with Industry Expert</h4>
                                    </p>
                                    <p>
                                        <span className="livemarker">LIVE</span>
                                        <span className="livemarker">LIVE</span>
                                        <small className="d-block font-weight-bold text-primary">MODULE 2</small>
                                        <h4>Digital products and services</h4>
                                        <span className="font-weight-light">Customer value delivery is enhanced through the creation of appropriate digital products and services
                                            <ul>
                                                <li>Information content of products and services is maximized</li>
                                                <li>Relationships are enhanced through digital connectivity with customers</li>
                                                <li>Mass customization is the norm</li>
                                            </ul>
                                        </span>
                                    </p>
                                    <p>
                                        <span className="livemarker">LIVE</span>
                                        <h4>Live Virtual Group Work led by Program Coach</h4>
                                    </p>
                                    <p>
                                        <span className="livemarker">LIVE</span>
                                        <span className="livemarker">LIVE</span>
                                        <small className="d-block font-weight-bold text-primary">MODULE 3</small>
                                        <h4>Intelligent data platform</h4>
                                        <span className="font-weight-light">An intelligent integrated data platform lies at the core of a smart digital roadmap
                                            <ul>
                                                <li>Big data is a core strength</li>
                                                <li>Smart analytics are employed</li>
                                                <li>Power of Internet of Things (IOT) is leveraged</li>
                                            </ul>
                                        </span>
                                    </p>
                                    <p>
                                        <span className="livemarker">LIVE</span>
                                        <h4>Live Virtual Session with Industry Expert</h4>
                                    </p>
                                    <p>
                                        <span className="livemarker">LIVE</span>
                                        <span className="livemarker">LIVE</span>
                                        <small className="d-block font-weight-bold text-primary">MODULE 4</small>
                                        <h4>Intelligent data platform</h4>
                                        <span className="font-weight-light">An intelligent integrated data platform lies at the core of a smart digital roadmap
                                            <ul>
                                                <li>Big data is a core strength</li>
                                                <li>Smart analytics are employed</li>
                                                <li>Power of Internet of Things (IOT) is leveraged</li>
                                            </ul>
                                        </span>
                                    </p>
                                    <p>
                                        <span className="livemarker">LIVE</span>
                                        <h4>Live Virtual Group Work led by Program Coach</h4>
                                    </p>
                                    <p>
                                        <span className="livemarker">LIVE</span>
                                        <span className="livemarker">LIVE</span>
                                        <small className="d-block font-weight-bold text-primary">MODULE 5</small>
                                        <h4>Intelligent data platform</h4>
                                        <span className="font-weight-light">An intelligent integrated data platform lies at the core of a smart digital roadmap
                                            <ul>
                                                <li>Big data is a core strength</li>
                                                <li>Smart analytics are employed</li>
                                                <li>Power of Internet of Things (IOT) is leveraged</li>
                                            </ul>
                                        </span>
                                    </p>
                                    <p>
                                        <span className="livemarker">LIVE</span>
                                        <small className="d-block font-weight-bold text-warning">POST PROGRAM</small>
                                        <span className="font-weight-light">
                                            <div className="row">
                                                <div className="col-1 text-right">
                                                    <FontAwesomeIcon icon={faAward} className="mr-2" size="lg" style={{ color: "orange" }} />
                                                </div>
                                                <div className="col-11">
                                                    Record of attendance from the <br /><span className="font-weight-bold">University of California, Los Angeles (UCLA Extension)</span>
                                                </div>
                                            </div>
                                        </span>
                                        <ul>
                                            <li>Knowledge updates</li>
                                            <li>Alumni Community</li>
                                            <li>Social interactions</li>
                                        </ul>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid py-5 program-content-web" style={{ background: "#EFEFEF" }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4">
                                <h2>Who should take this course?</h2>
                            </div>
                            <div className="col-md-8 ">
                                <p>This program is designed for a broad range of executives across industry sectors. Managers of all functions and senior executives can learn to effectively analyze, articulate and apply key digital management and leadership insights in their teams and organizations.</p>
                                <h5>Take this short quiz to find out if this program will benefit you</h5>
                                <p>
                                    <ul>
                                        <li>
                                            <p>Are you are seeking to leverage the benefit of digital technologies in your function or business?</p>
                                        </li>
                                        <li>
                                            <p>Are you are interested to learn more about the leading trends in digital technologies such as platform business models and artificial intelligence?</p>
                                        </li>
                                        <li>
                                            <p>Are you are keen to apply digital technologies to drive business innovation and new product development?</p>
                                        </li>
                                        <li>
                                            <p>Are you want to use the power of digital technologies to deliver more value to your customers such as through personalization and new value adding services?</p>
                                        </li>
                                        <li>
                                            <p>Are you are a senior executive wishing to create a strategy for leading digital transformation within your organization?</p>
                                        </li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid py-5 program-content-web bg-white">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4">
                                <h2>Your Faculty</h2>
                            </div>
                            <div className="col-md-8 ">
                                <p>You will learn from world class faculty in this program. Possible faculty teaching in this program include -</p>
                                <h5>Professor Kartik Hosanagar</h5>
                                <p>Kartik Hosanagar is the John C. Hower Professor of Technology and Digital Business and a Professor of Marketing at The Wharton School of the University of Pennsylvania. Kartik’s research work focuses on the digital economy, in particular the impact of analytics and algorithms on consumers and society, Internet media, Internet marketing and e-commerce.</p>
                                <p>Kartik serves as a department editor at the journal Management Science and has previously served as a Senior Editor at the journals Information Systems Research and MIS Quarterly. He is a ten-time recipient of MBA or Undergraduate teaching excellence awards at the Wharton School and has been recognized as one of the world’s top 40 business professors under 40.</p>

                                <h5>Professor Soumitra Dutta</h5>
                                <p>Soumitra Dutta is a Professor of Management at and the former founding dean of the SC Johnson College of Business at Cornell University, New York. He is the founder of the Global Innovation Index, published by the World Intellectual Property Organization and was the co-editor of the Global Information Technology Report, published by the World Economic Forum
– two influential reports in technology and innovation policy. He is also the founder and President of Portulans Institute, a non-partisan nonprofit research and education institution based in Washington DC.</p>
                                <p>Dutta is on the global boards of Sodexo and Dassault Systemes. He is
also a member of the Shareholder Council of Chicago-based healthcare consulting company ZS Associates. Dutta is a member of the Davos Circle, an association of long-time participants in the Annual Davos meeting of the World Economic Forum. He was the co-chair of the World Economic Forum’s Global Future Council on Innovation Ecosystems. He is also the Chief Academic Advisor to the Artificial Intelligence Forum of the Confederation of Indian Industry).</p>

                                <h5>Professor Tawfik Jelassi</h5>
                                <p>Tawfik Jelassi is Professor of Strategy and Technology Management at IMD. He is also Co-Director of the Orchestrating Winning Performance (OWP) program in Lausanne and Singapore/Dubai, which is the school’s largest executive education program with over 600 participants enrolled per year. His major research, teaching and consulting interests are in the areas of digital business transformation and leadership in turbulent times. He was granted several excellence awards for his teaching and research in Europe and the USA, and has received two national decorations from the President of Tunisia: “The Order of merit in the fields of education and science” and “The Order of the Republic”.</p>
                                <p>Before joining IMD, Dr. Jelassi was Minister of Higher Education, Scientific Research, and Information & Communication Technologies in the transition- to-democracy Government of Tunisia (2014-2015). Prior to that, he was Chairman of the Board of Directors of Ooredoo Tunisia, the leading mobile telecom operator in the country. Between 2000 and 2013, he was Professor and Dean of the Business School at Ecole Nationale des Ponts et Chaussées (Paris). Previously he was Coordinator of the Technology Management Department and Associate Professor at INSEAD (Fontainebleau)</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Program;