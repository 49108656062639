import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import Academy from "./components/Academy/Academy"
import Program from "./components/Academy/Program"
import Consult from "./components/Academy/Consult"
import Post from "./components/Academy/Post"
import Programs from "./components/Academy/Programs"
import Insights from "./components/Insights/insights"
import Home from "./components/Home"
import './styles.scss';
import { Alert, Button } from 'react-bootstrap'


export default function App() {
  const [show, setShow] = useState(true);

  return (
    <Router>
      <div>
        <Alert className="rounded-0 py-2 mb-0" show={show} variant="light" >
          <div className="container">
          <div className="row">
            <div className="col-9 col-md-10">
              <p className="mb-0 small">
                Please note that this website is for internal consumption only and not intended for external distribution.
              </p>
            </div>
            <div className="col-3 col-md-2 text-right">
              <p onClick={() => setShow(false)} className="small text-danger mb-0" style={{cursor: "pointer"}}>Close</p>
          </div>
          </div>
          </div>
        </Alert>

        

        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
          <Route path="/academy">
            <Academy />
          </Route>
          <Route path="/insights">
            <Insights />
          </Route>
          <Route path="/programs">
            <Programs />
          </Route>
          <Route path="/program">
            <Program />
          </Route>
          <Route path="/post">
            <Post />
          </Route>
          <Route path="/consult">
            <Consult />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}